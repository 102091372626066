<template>
  <div class="edit-group">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.ingame.edit_group.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="record">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12 sm6">
              <table class="object-table">
                <tr>
                  <td>{{__('entity.common.icon')}}:</td>
                  <td><img :src="originalRecord.icon" alt="no icon"/></td>
                </tr>
                <tr>
                  <td>{{__('entity.common.id')}}:</td>
                  <td>{{ originalRecord.id}}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.name')}}:</td>
                  <td>{{ originalRecord.name }}</td>
                </tr>
                <tr>
                  <td>key:</td>
                  <td>{{ originalRecord.key }}</td>
                </tr>
                <tr>
                  <td>{{__('sorting')}}:</td>
                  <td>{{ originalRecord.sorting }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.section')}}:</td>
                  <td v-if="originalRecord.parent">{{ originalRecord.parent.name }}</td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>{{__('hidden')}}:</td>
                  <td>{{ originalRecord.hidden }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.created_at')}}:</td>
                  <td>{{ originalRecord.createdAt }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.updated_at')}}:</td>
                  <td>{{ originalRecord.updatedAt }}</td>
                </tr>
              </table>
            </div>
            <div class="flex xs12 sm6">
              <div class="layout layout--wrap">
                <div class="flex xs12 mt">
                  <text-field
                          v-model="record.name"
                          :label="__('entity.common.name')"
                          :error-messages="errors.name"
                          maxlength="50"
                          :prepend-icon="isChanged('name') ? 'pencil' : null"
                          @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-field
                          v-model="record.key"
                          :label="'key'"
                          :error-messages="errors.key"
                          maxlength="50"
                          :prepend-icon="isChanged('key') ? 'pencil' : null"
                          @input="registerChange('key')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                      v-model="record.icon"
                      :label="__('entity.common.icon')"
                      :error-messages="errors.icon"
                      :prepend-icon="isChanged('icon') ? 'pencil' : null"
                      @input="registerChange('icon')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                      v-model="record.sorting"
                      :label="__('sorting')"
                      :type="'number'"
                      :error-messages="errors.sorting"
                      :prepend-icon="isChanged('sorting') ? 'pencil' : null"
                      @input="registerChange('sorting')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.common.group') }}</label>
                  <select-reference
                          v-model="record.parentId"
                          :endpoint="'/ingame/items/group'"
                          :nothing-selected-message="__('common.no_group_selected')"
                          :prepend-icon="isChanged('parentId') ? 'pencil' : ''"
                          @input="registerChange('parentId')"
                  />
                </div>
                <div class="flex xs12">
                  <my-switch
                      v-model="record.hidden"
                      :label="__('entity.common.hidden')"
                      :append-icon="isChanged('hidden') ? 'pencil' : null"
                      @input="registerChange('hidden')"
                  ></my-switch>
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import {editFormMixin} from "../../mixins/editForm";

  export default {
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/items/group',
      emptyRecord: {
        id: null,
        name: '',
        sorting: 0,
        parentId: null,
        createdAt: '---',
        updatedAt: '---'
      },
    }),
  }
</script>